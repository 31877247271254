
import { defineComponent, onMounted, ref, watchEffect } from "vue";
import WidgetOrders from "@/components/widgets/mixed/WidgetOrders.vue";
import WidgetOrdersDaily from "@/components/widgets/mixed/WidgetOrdersDaily.vue";
import WidgetOrdersMonthly from "@/components/widgets/mixed/WidgetOrdersMonthly.vue";
import WidgetOrdersYearly from "@/components/widgets/mixed/WidgetOrdersYearly.vue";
import BussinessStatistics from "@/modules/dashboard/components/BussinessStatistics.vue";
import { Apollo } from "@/core/services";
import { GET_WIDGETS } from "@/modules/dashboard/graphql/Queries";
// import InnerLoader from "../../../components/InnerLoader.vue";
import DashboardContentLoader from "./DashboardContentLoader.vue";
import { useI18n } from "vue-i18n";
import { asideTheme } from "@/core/helpers/config";

export default defineComponent({
	name: "dashboard",
	components: {
		WidgetOrders,
		WidgetOrdersDaily,
		WidgetOrdersMonthly,
		WidgetOrdersYearly,
		BussinessStatistics,
		// InnerLoader,
		DashboardContentLoader,
	},
	setup() {
		const loader = ref(false);
		const glob: any = ref(asideTheme);
		const widgets_data = ref("");
		const i18n = useI18n();
		const systemLocale = ref();
		const refresh = ref();

    watchEffect(() => {
			systemLocale.value = i18n.fallbackLocale.value;
			refresh.value = systemLocale.value+glob.value;
		});
		const getWidgets = async () => {
			loader.value = true;
			await Apollo.watchQuery({
				query: GET_WIDGETS,
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-only",
				errorPolicy: "all",
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loader.value = false;
				}
				widgets_data.value = JSON.parse(data.widgets);
				loader.value = false;
			});
		};

		onMounted(() => {
			getWidgets();
		});

		return {
			widgets_data,
			loader,
			i18n,
			systemLocale,
			refresh,
		};
	},
});
