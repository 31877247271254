import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row gy-5 g-xl-8 mb-5 mb-xl-10" }
const _hoisted_2 = { class: "col-xl-6" }
const _hoisted_3 = { class: "col-xl-6" }
const _hoisted_4 = { class: "row gy-5 g-xl-8 mb-5 mb-xl-10" }
const _hoisted_5 = { class: "col-xl-4" }
const _hoisted_6 = { class: "col-xl-4" }
const _hoisted_7 = { class: "col-xl-4" }
const _hoisted_8 = { class: "row gy-5 gx-xl-8" }
const _hoisted_9 = { class: "row gy-5 gx-xl-8" }
const _hoisted_10 = { class: "col-xl-8" }
const _hoisted_11 = { class: "col-xl-4" }
const _hoisted_12 = { class: "col-xl-12 mt-0 latest-orders-details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardContentLoader = _resolveComponent("DashboardContentLoader")!
  const _component_WidgetOrders = _resolveComponent("WidgetOrders")!
  const _component_WidgetOrdersDaily = _resolveComponent("WidgetOrdersDaily")!
  const _component_WidgetOrdersMonthly = _resolveComponent("WidgetOrdersMonthly")!
  const _component_WidgetOrdersYearly = _resolveComponent("WidgetOrdersYearly")!
  const _component_BussinessStatistics = _resolveComponent("BussinessStatistics")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _withDirectives(_createVNode("div", null, [
      _createVNode(_component_DashboardContentLoader)
    ], 512), [
      [_vShow, _ctx.loader]
    ]),
    _withDirectives(_createVNode("div", null, [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_WidgetOrders, {
            icon: require('@/assets/media/icons/total-sales.svg'),
            title: 'message.TOTAL_SALES',
            titleDescription: 'message.TOTAL_SALES_AMOUNT',
            background: "lightcyan",
            getKey: _ctx.widgets_data.total_sales,
            link: "#"
          }, null, 8, ["icon", "title", "titleDescription", "getKey"])
        ]),
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_WidgetOrders, {
            icon: require('@/assets/media/icons/monthly-sales.svg'),
            title: 'message.TOTAL_MONTHLY_SALES',
            titleDescription: 'message.CURRENT_MONTH_SALES',
            getKey: _ctx.widgets_data.monthly_sales,
            link: "#"
          }, null, 8, ["icon", "title", "titleDescription", "getKey"])
        ])
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode("div", _hoisted_5, [
          _createVNode(_component_WidgetOrders, {
            icon: require('@/assets/media/icons/total-orders.svg'),
            title: 'message.TOTAL_ORDERS',
            titleDescription: 'message.TOTAL_ORDER_PLACED',
            link: "/orders",
            getKey: _ctx.widgets_data.total_order
          }, null, 8, ["icon", "title", "titleDescription", "getKey"])
        ]),
        _createVNode("div", _hoisted_6, [
          _createVNode(_component_WidgetOrders, {
            icon: require('@/assets/media/icons/orders-process.svg'),
            title: 'message.ORDERS_IN_PROCESS',
            titleDescription: 'message.TOTAL_ORDERS_IN_PROCESS',
            link: "/orders",
            background: "lightcyan",
            getKey: _ctx.widgets_data.order_process
          }, null, 8, ["icon", "title", "titleDescription", "getKey"])
        ]),
        _createVNode("div", _hoisted_7, [
          _createVNode(_component_WidgetOrders, {
            icon: require('@/assets/media/icons/customers.svg'),
            title: 'message.CUSTOMERS',
            titleDescription: 'message.TOTAL_CUSTOMERS',
            background: "lightcyan",
            link: "/customer/lists",
            getKey: _ctx.widgets_data?.customers_count
          }, null, 8, ["icon", "title", "titleDescription", "getKey"])
        ])
      ]),
      _createVNode("div", _hoisted_8, [
        _createVNode("div", null, [
          _createVNode(_component_WidgetOrdersDaily, {
            "widget-classes": "mb-5 mb-xl-8",
            "chart-color": "primary",
            chartHeight: "350",
            icon: require('@/assets/media/icons/daily-sales.svg'),
            title: 'message.DAILY_SALES',
            titleDescription: 'message.MONTHLY_SALES_BY_DAY',
            locale: _ctx.systemLocale,
            background: "lightcyan",
            key: _ctx.refresh,
            link: "#"
          }, null, 8, ["icon", "title", "titleDescription", "locale"])
        ])
      ]),
      _createVNode("div", _hoisted_9, [
        _createVNode("div", _hoisted_10, [
          _createVNode(_component_WidgetOrdersMonthly, {
            "widget-classes": "mb-5 mb-xl-8",
            "chart-color": "primary",
            chartHeight: "300",
            icon: require('@/assets/media/icons/monthly-sales-graph.svg'),
            title: 'message.MONTHLY_SALES',
            titleDescription: 'message.YEARLY_SALES_BY_MONTH',
            i18n: _ctx.i18n,
            locale: _ctx.systemLocale,
            background: "lightcyan",
            key: _ctx.refresh,
            link: "#"
          }, null, 8, ["icon", "title", "titleDescription", "i18n", "locale"])
        ]),
        _createVNode("div", _hoisted_11, [
          _createVNode(_component_WidgetOrdersYearly, {
            "widget-classes": "mb-5 mb-xl-8",
            "chart-color": "primary",
            chartHeight: "300",
            icon: require('@/assets/media/icons/yearly-sales.svg'),
            title: 'message.YEARLY_SALES',
            titleDescription: 'message.YEARLY_SALES_BY_YEAR',
            background: "lightcyan",
            link: "#"
          }, null, 8, ["icon", "title", "titleDescription"])
        ]),
        _createVNode("div", _hoisted_12, [
          _createVNode(_component_BussinessStatistics)
        ])
      ])
    ], 512), [
      [_vShow, !_ctx.loader]
    ])
  ], 64))
}