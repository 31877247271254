
import { defineComponent, ref, onMounted } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { Apollo } from "@/core/services";
import { ANNUAL_REVENUE } from "@/modules/dashboard/graphql/Queries";
import { config, asideTheme } from "@/core/helpers/config";

export default defineComponent({
	name: "WidgetOrders",
	props: {
		widgetClasses: String,
		chartColor: String,
		chartHeight: String,
		icon: String,
		title: String,
		titleDescription: String,
		count: String,
		background: String,
		link: String,
	},
	setup(props) {
		const color = ref(props.chartColor);

		const baseColor = getCSSVariableValue("--bs-" + color.value);
		const lightColor = getCSSVariableValue("--bs-light-" + color.value);
		const labelColor = getCSSVariableValue("--bs-" + "gray-700");
		const glob = ref(asideTheme);

		const chartOptions = {
			chart: {
				width: 380,
				type: "donut",
			},
			labels: [
				{
					colors: [glob.value == "dark" ? "#ffdd00" : "#000"],
				},
			],
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
						},
						legend: {
							position: "bottom",
						},
					},
				},
			],
		};

		const series = ref([0]);

		const annualRevenue = () => {
			Apollo.watchQuery({
				query: ANNUAL_REVENUE,
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-only",
				errorPolicy: "all",
			}).subscribe(({ data, errors }) => {
				const annual_revenue = JSON.parse(data.annual_revenue);
				annual_revenue.forEach((revenue) => {
					chartOptions.labels.push(revenue.date);
					series.value.push(parseFloat(revenue.order_total));
				});
				chartOptions.labels.shift();
				series.value.shift();
			});
		};

		onMounted(() => {
			annualRevenue();
		});

		return {
			series,
			chartOptions,
			glob,
		};
	},
});
